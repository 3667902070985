<template>
  <aside class="sidebar">
    <nav class="sidebar__navigation-list">
      <div class="sidebar__main-list">
        <NavigationItem
          v-for="(navigation, index) in mainNavigationList"
          :key="index"
          class="sidebar__navigation-item"
          :navigation="navigation"
          :active="isActive(navigation)"
          system
          :notification="notificationList[index]"
          @click="navItemClickHandler(navigation)"
        />
        <div
          v-if="navigationList.length || isShowMoreButton"
          class="sidebar__navigation-item"
        >
          <div class="sidebar__divider"></div>
        </div>
      </div>

      <div ref="serviceListContainer" class="sidebar__service-list">
        <NavigationItem
          v-for="(navigation, index) in navigationList"
          :key="index"
          class="sidebar__navigation-item"
          :navigation="navigation"
          :active="isActive(navigation)"
          :background="isInBackground(navigation)"
          @click="navItemClickHandler(navigation)"
          @mouseenter.self="navigationItemMouseEnterHandler($event, navigation)"
          @mouseleave.self="navigationItemMouseLeaveHandler"
        />
        <NavigationItem
          v-if="isShowMoreButton"
          id="moreButton"
          ref="moreButton"
          class="sidebar__navigation-item sidebar__navigation-item_more"
          :navigation="moreNavigationItem"
          :background="isMoreBackground"
          :background-active="isMoreBackgroundActive"
          @click="moreClickHandler"
        />
      </div>
      <div class="sidebar__bottom-list">
        <NavigationItem
          class="sidebar__navigation-item"
          :navigation="profileNavigationItem"
          :notification="profileNotifications"
          :active="isActive(profileNavigationItem)"
          @click="navItemClickHandler(profileNavigationItem)"
        />
      </div>
    </nav>
    <TNTooltip
      class="sidebar__tooltip"
      :class="{ sidebar__tooltip_visible: isTooltipVisible }"
      light
      no-scroll
      arrow
      position="left"
      :style="tooltipStyle"
    >
      <span v-html="tooltipBody" />
    </TNTooltip>
    <transition name="fade-100ms">
      <div
        v-if="isShowMoreMenu"
        v-click-outside="closeMoreMenu"
        class="sidebar__more-dropdown"
        :style="{ left: moreLeft + 'px', bottom: moreBottom + 'px' }"
      >
        <NavigationItem
          v-for="(navigation, index) in navigationMoreList"
          :key="index"
          class="sidebar__navigation-item"
          :navigation="navigation"
          :active="isActive(navigation)"
          :background="isInBackground(navigation)"
          @click="navItemClickHandler(navigation)"
        />
      </div>
    </transition>
  </aside>
</template>

<script lang="ts" src="./sidebar.ts"></script>
<style src="./sidebar.css"></style>
