import { getBase64 } from "@/infrastructure/infrastructure-adapters";
import { GrpcConfig } from "@/infrastructure/request-params";
import { MainService } from "@/service";

const service = new MainService();

export async function DownloadUrl(
  url: string,
  name: string,
  withCredentials: boolean = false,
  saveElectron: boolean = false,
  headers: Record<string, string> = {}
): Promise<string> {
  let retryCount = 2;
  const sendRequest = async (): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (withCredentials) {
        headers = Object.assign(headers, GrpcConfig().metadata);
      }
      fetch(url, {
        headers
      })
        .then(async response => {
          if (response.status === 200) {
            const reader = response.body!.getReader();

            let receivedLength = 0;
            const chunks: Uint8Array[] = [];
            let isPending = true;
            let mimeType = "";

            while (isPending) {
              const { done, value } = await reader.read();

              if (done) {
                isPending = false;
                break;
              }

              chunks.push(value);
              receivedLength += value.length;
            }

            mimeType = response.headers.get("content-type") || "text/plain";

            const chunksAll = new Uint8Array(receivedLength);
            let position = 0;

            for (const chunk of chunks) {
              chunksAll.set(chunk, position);
              position += chunk.length;
            }

            if (!chunksAll.length) {
              reject("Empty chunks");
            }

            const baseFile = `data:${mimeType || "image/png"};base64,${getBase64(
              chunksAll
            )}`;

            if (saveElectron) {
              resolve(baseFile);
            }

            const file = service.baseToFile(baseFile, name);

            resolve(URL.createObjectURL(file));
          } else {
            if (retryCount > 0) {
              retryCount--;
              setTimeout(async () => {
                try {
                  const r = await sendRequest();

                  resolve(r);
                } catch (e) {
                  reject(e);
                }
              }, 500);
            } else {
              reject();
            }
          }
        })
        .catch(e => {
          if (retryCount > 0) {
            retryCount--;
            setTimeout(async () => {
              try {
                const r = await sendRequest();

                resolve(r);
              } catch (e) {
                reject(e);
              }
            }, 500);
          } else {
            reject(e);
          }
        });
    });
  };

  return await sendRequest();
}
