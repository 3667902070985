import { Fancybox } from "@fancyapps/ui/dist/fancybox.umd";
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  watch
} from "vue";
import "@fancyapps/ui/dist/fancybox.css";
import VuePdfEmbed from "vue-pdf-embed";

import { fancyboxLocaleRu } from "@/common/constants";
import { fancyboxPreviewAdapter } from "@/components/image-previewer/image-previewer-adapters";
import { useInfrastructure } from "@/composables/infrastructure";
import store from "@/store";
import { IAttachment } from "@/store/modules/stream/i-stream";

export default defineComponent({
  name: "ImagePreviewer",
  components: {
    VuePdfEmbed
  },
  props: {
    imageList: {
      type: Array as PropType<IAttachment[]>,
      required: true
    },
    initIndex: { type: Number },
    pdfMode: Boolean,
    shareable: Boolean,
    file: Boolean,
    noDownload: Boolean
  },
  emits: ["close", "download", "share", "error", "rendered"],
  setup: (props, { emit }) => {
    const $infra = useInfrastructure();

    const isAttachmentsLoad = ref<boolean>(false);
    const isFileDownload = ref<boolean>(false);
    const fancyboxContainer = ref<HTMLElement | null>(null);
    const pdfSource = ref<string>("");
    const pageCount = ref<number>(1);
    const page = ref<number>(1);
    const pdfComponent = ref<typeof VuePdfEmbed | null>(null);
    const pdfContainer = ref<HTMLElement | null>(null);
    const zoomScale = ref<number>(1);

    let fancybox: any = null;

    onMounted(() => {
      window.addEventListener("resize", calculatePdfSize);
      document.body.addEventListener("keyup", keyUpHandler);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", calculatePdfSize);
      document.body.removeEventListener("keyup", keyUpHandler);
    });

    const isShowBackground = computed<boolean>(() => !!props.imageList.length);

    watch(
      () => props.imageList,
      () => {
        page.value = 1;
        showWindow();
      },
      { deep: true }
    );

    const showWindow = () => {
      if (!props.imageList.length && fancyboxContainer.value) {
        fancyboxContainer.value.innerHTML = "";

        return;
      }
      isAttachmentsLoad.value = true;
      const list = props.imageList;

      if (props.pdfMode && list?.[0]) {
        pdfSource.value =
          store.state.fileList.find(file => file.uuid === list[0].uuid)?.base ||
          "";
        if (!pdfSource.value) {
          pdfSource.value = props.imageList[0].base || "";
        }

        return;
      }
      fancyboxPreviewAdapter(
        props.imageList,
        props.file,
        props.noDownload
      ).then(async list => {
        isAttachmentsLoad.value = false;
        await nextTick();
        fancybox = Fancybox.show(list, {
          l10n: fancyboxLocaleRu,
          startIndex: props.initIndex || 0,
          dragToClose: false,
          parentEl: fancyboxContainer.value,
          click: () => {}
        });
      });
    };
    const handleDocumentRender = async () => {
      calculatePdfSize();
      await nextTick();
      isAttachmentsLoad.value = false;
      emit("rendered");
    };
    const calculatePdfSize = () => {
      if (pdfComponent.value && pdfContainer.value) {
        const canvas = pdfContainer.value.querySelector("canvas");

        if (canvas) {
          const isVertical = canvas.height > canvas.width;
          const width = Number(canvas.style.width.replace("px", ""));
          const height = Number(canvas.style.height.replace("px", ""));
          let scale: number;

          if (isVertical) {
            scale = window.innerHeight / height;
          } else {
            if (window.innerHeight < width) {
              scale = window.innerHeight / height;
            } else {
              scale = window.innerWidth / width;
            }
          }
          canvas.style.width = scale * width * zoomScale.value + "px";
          canvas.style.height = scale * height * zoomScale.value + "px";
        }
        pageCount.value = pdfComponent.value.pageCount || 1;
      }
    };
    const keyUpHandler = (event: KeyboardEvent) => {
      if (event.code === "Escape") {
        closeWindow();
      } else if (event.code === "ArrowRight") {
        nextPage();
      } else if (event.code === "ArrowLeft") {
        previousPage();
      }
    };
    // todo: zoom
    // const scrollHandler = (event: WheelEvent) => {
    //   if (pdfComponent.value && pdfContainer.value) {
    //     zoomScale.value += event.deltaY / 100;
    //     if (zoomScale.value < 0.5) {
    //       zoomScale.value = 0.5;
    //     } else if (zoomScale.value > 5) {
    //       zoomScale.value = 5;
    //     }
    //     calculatePdfSize();
    //   }
    // };
    const previousPage = () => {
      if (page.value !== 1) {
        page.value--;
      } else {
        return;
      }
      if (props.pdfMode) isAttachmentsLoad.value = true;
    };
    const nextPage = () => {
      if (page.value !== pageCount.value) {
        page.value++;
      } else {
        return;
      }
      if (props.pdfMode) isAttachmentsLoad.value = true;
    };
    const closeWindow = async () => {
      (document.body.parentNode as HTMLElement).classList.remove(
        "with-fancybox"
      );
      emit("close");
    };
    const download = () => {
      emit("download");
    };
    const share = async () => {
      const imageItem = {
        ...(props.pdfMode
          ? props.imageList[0]
          : props.imageList[fancybox?.Carousel?.pageIndex || 0])
      };

      if (imageItem.base?.startsWith("http")) {
        isFileDownload.value = true;
        const data = await $infra.file.Download(imageItem.uuid, imageItem.type);
        const baseFile = await data.promise;

        imageItem.base = baseFile.base;
        isFileDownload.value = false;
      }

      if (props.pdfMode && props.imageList.length) {
        emit("share", imageItem);
      } else if (props.imageList) {
        emit("share", imageItem);
      }
    };
    const errorHandler = () => {
      emit("error");
    };

    return {
      isAttachmentsLoad,
      fancyboxContainer,
      pdfSource,
      pageCount,
      page,
      pdfComponent,
      pdfContainer,
      isShowBackground,
      isFileDownload,
      errorHandler,
      previousPage,
      showWindow,
      nextPage,
      closeWindow,
      download,
      share,
      handleDocumentRender
    };
  }
});
