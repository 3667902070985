import { GetSettingsResponse } from "@superapp/life-proto/pkg-ts/tnlife/chat/settings/settings_service_pb";
import { MutationTree } from "vuex";

import { LSNames, SSNames } from "@/common/constants";
import Infrastructure from "@/infrastructure";
import { Electron } from "@/infrastructure/electron";
import { ISettingsStore } from "@/store/modules/settings/i-settings";
import { IApp } from "@/views/services/i-services";

const Infra = new Infrastructure();

export default {
  enableDeveloperMode(state) {
    localStorage.setItem(LSNames.Dev, "1");
    state.developerMode = true;
  },
  getCustomServiceList(state) {
    try {
      const customServiceList = JSON.parse(
        localStorage.getItem(LSNames.CustomServiceList) || "[]"
      );

      if (customServiceList) {
        state.customServiceList = customServiceList;
      }
    } catch {}
  },
  setRingtoneEnabled(state, enabled: boolean) {
    state.isRingtoneEnabled = enabled;
    localStorage.setItem(LSNames.RingtoneEnabled, Number(enabled).toString());
  },
  setShowSidebarTooltip(state, enabled: boolean) {
    state.showSidebarTooltip = enabled;
    localStorage.setItem(LSNames.ShowTooltip, Number(enabled).toString());
  },
  setCallDeviceSettings(
    state,
    settings: {
      audioOutput?: {
        id: string;
        label: string;
      };
      audioInput?: {
        id: string;
        label: string;
      };
      videoInput?: {
        id: string;
        label: string;
      };
    }
  ) {
    state.callDeviceSettings = { ...settings };
    localStorage.setItem(
      LSNames.CallSettings,
      JSON.stringify(state.callDeviceSettings)
    );
  },
  setStageMode(state, stageMode: boolean) {
    state.stageMode = stageMode;
  },
  setOpenAtLogin(state, set: boolean) {
    state.isOpenAtLogin = set;
    localStorage.setItem(LSNames.OpenAtLogin, set ? "1" : "0");
    Electron().ipcRenderer.send("set-open-at-login", set);
  },
  setConfig(state, setting: GetSettingsResponse.AsObject) {
    if (setting.maxavatarsize > 0) {
      state.maxAvatarSize = setting.maxavatarsize;
    }
    if (setting.maxchatfilesize > 0) {
      state.maxChatFileSize = setting.maxchatfilesize;
    }
    if (setting.maxminiappfilesize > 0) {
      state.maxServiceFileSize = setting.maxminiappfilesize;
    }
    if (setting.meetdomain) {
      state.meetDomain = setting.meetdomain;
    }
    if (setting.usermanuallink) {
      state.userManualLink = setting.usermanuallink;
    }
    if (setting.calltimeout > 0) {
      state.callTimeout = setting.calltimeout;
    }
    if (setting.meetlandingurl) {
      state.meetLandingUrl = setting.meetlandingurl;
    }
    if (setting.enablemarkallstreamsasread) {
      state.enableMarkAllStreamsAsRead = setting.enablemarkallstreamsasread;
    }
    if (setting.trackermaxfilesize) {
      state.trackerMaxFileSize = setting.trackermaxfilesize;
    }
    if (setting.trackermaxfilescount) {
      state.trackerMaxFilesCount = setting.trackermaxfilescount;
    }
    if (setting.trackerurl) {
      sessionStorage.setItem(SSNames.TrackerUrl, setting.trackerurl);
    }
    if (setting.messagedeletionabilitytimeout) {
      state.messageDeletionAbilityTimeout =
        setting.messagedeletionabilitytimeout;
    }
    if (setting.oauthurl) {
      try {
        state.profileOrigin = new URL(setting.oauthurl).origin;
      } catch {}
    }
    if (setting.nolobby) {
      state.noLobby = setting.nolobby;
    }
    state.accessViaProfile = setting.accessviaprofile;
    if (setting.messageeditingabilitytimeout) {
      state.messageEditingAbilityTimeout = setting.messageeditingabilitytimeout;
    }
    state.enableTracker = setting.enabletracker;
    state.showCallQualityReviewPage = setting.showcallqualityreviewpage;
    localStorage.setItem(
      LSNames.DynLinkOrigins,
      setting.dynlinkdomainsList.join(",")
    );
  },
  addCustomService(state, service: IApp) {
    const serviceIndex = state.customServiceList.findIndex(
      s => s.uuid === service.uuid
    );

    if (serviceIndex >= 0) {
      state.customServiceList.splice(serviceIndex, 1, service);
    } else {
      state.customServiceList.push(service);
    }
    localStorage.setItem(
      LSNames.CustomServiceList,
      JSON.stringify(state.customServiceList)
    );
  },
  removeCustomService(state, serviceId: string) {
    const serviceIndex = state.customServiceList.findIndex(
      cs => cs.uuid === serviceId
    );

    if (serviceIndex !== -1) {
      state.customServiceList.splice(serviceIndex, 1);
      localStorage.setItem(
        LSNames.CustomServiceList,
        JSON.stringify(state.customServiceList)
      );
    }
  },
  getShortLink(state) {
    Infra.dynLink
      .SetPayload("index")
      .then(link => {
        state.shortLink = link;
      })
      .catch(() => {
        state.shortLink = "";
      });
  }
} as MutationTree<ISettingsStore>;
