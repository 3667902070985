import { File as OuterFile } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/file_pb";
import { PlannedMeetStatus } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/meet_pb";
import { MessageEntity } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/message_entity_pb";
import { Message } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/message_pb";
import { UserPresenceStatus } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/presence_pb";
import { Stream as OuterStream } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/stream_pb";
import {
  CallType,
  Notification as OuterNotification
} from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/system_notify_pb";
import { AppTheme } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/theme_pb";
import { UserStatus } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/user_status_pb";

import { formatLastUpdatedAt, getMessageBody } from "@/common/functions";
import Infrastructure from "@/infrastructure";
import { WebSocket } from "@/infrastructure/websocket/websocket";
import { IUser } from "@/store/i-store";
import { INotification } from "@/store/modules/notifications/i-notifications";

import Type = MessageEntity.Type;
import StreamType = OuterStream.Type;
import NotificationType = OuterNotification.Type;

export class Stream {
  constructor(payload: {
    id: string;
    type: StreamType;
    messages: IMessage[];
    createDate: Date;
    isPinned?: boolean;
    interlocutorId?: string;
    lastReadMessageId: string;
    isCanWrite: boolean;
    isCanLeave: boolean;
    iconUuid: string;
    iconUrl?: string;
    title: string;
    lastMessageBody: string | null;
    lastMessageAuthorName: string | null;
    lastMessageDateString: string | null;
    lastMessageDate: Date;
    lastMessageId: string | null;
    isPersonalChat: boolean;
    private: boolean;
    description: string;
    canEdit: boolean;
    ownerId: string;
    isSuperChannel: boolean;
    callData: IStreamCallData | null;
    oncomingMeeting: IStreamOncomingMeetData | null;
    meetData: IStreamMeetData | null;
    isArchived: boolean;
  }) {
    this.id = payload.id;
    this.type = payload.type;
    this.messages = payload.messages;
    this.createDate = payload.createDate;
    this.isPinned = payload.isPinned;
    this.interlocutorId = payload.interlocutorId;
    this.lastReadMessageId = payload.lastReadMessageId;
    this.isCanWrite = payload.isCanWrite;
    this.isCanLeave = payload.isCanLeave;
    this.iconUuid = payload.iconUuid;
    this.iconUrl = payload.iconUrl;
    this.isPersonalChat = payload.isPersonalChat;
    this.title = payload.title;
    this.lastMessageBody = payload.lastMessageBody;
    this.lastMessageAuthorName = payload.lastMessageAuthorName;
    this.lastMessageDateString = payload.lastMessageDateString;
    this.lastMessageDate = payload.lastMessageDate;
    this.lastMessageId = payload.lastMessageId;
    this.private = payload.private;
    this.description = payload.description;
    this.canEdit = payload.canEdit;
    this.ownerId = payload.ownerId;
    this.isSuperChannel = payload.isSuperChannel;
    this.callData = payload.callData;
    this.oncomingMeeting = payload.oncomingMeeting;
    this.meetData = payload.meetData;
    this.isArchived = payload.isArchived;
  }

  private Infra: Infrastructure = new Infrastructure();
  id: string;
  type: StreamType;
  messages: IMessage[];
  createDate: Date;
  isPinned?: boolean;
  interlocutorId?: string;
  lastReadMessageId: string;
  isCanWrite: boolean;
  isCanLeave: boolean;
  iconUuid: string;
  iconUrl?: string;
  isPersonalChat: boolean;
  isArchived: boolean;
  title: string;
  lastMessageBody: string | null;
  lastMessageAuthorName: string | null;
  lastMessageDateString: string | null;
  lastMessageDate: Date;
  lastMessageId: string | null;
  private: boolean;
  description: string;
  ownerId: string;
  canEdit: boolean;
  search: boolean = false;
  isSuperChannel: boolean = false;
  participants: IUser[] = [];
  callData: IStreamCallData | null = null;
  oncomingMeeting: IStreamOncomingMeetData | null = null;
  meetData: IStreamMeetData | null = null;

  getLastMessage(): IMessage | null {
    return this.messages[0] || null;
  }

  getOldestMessage(): IMessage | null {
    const messageList = [...this.messages];

    return messageList.reverse()[0] || null;
  }

  getChatMutedState(payload: {
    direct: INotification;
    groups: INotification;
    channels: INotification;
  }): boolean {
    const { direct, groups, channels } = payload;

    if (this.type === StreamType.DIRECT) {
      if (direct.muted) {
        return !direct.uuidList.includes(this.id);
      } else {
        return direct.uuidList.includes(this.id);
      }
    } else if (this.type === StreamType.GROUP) {
      if (groups.muted) {
        return !groups.uuidList.includes(this.id);
      } else {
        return groups.uuidList.includes(this.id);
      }
    } else if (this.type === StreamType.CHANNEL) {
      if (channels.muted) {
        return !channels.uuidList.includes(this.id);
      } else {
        return channels.uuidList.includes(this.id);
      }
    }

    return false;
  }

  markStreamAsRead(): Promise<void> {
    return new Promise((resolve, reject) => {
      const lastMessage = this.lastMessageId;

      if (lastMessage) {
        this.Infra.notifications
          .MarkAsRead({
            messageId: lastMessage,
            streamId: this.id
          })
          .then(() => {
            this.lastReadMessageId = lastMessage;
            resolve();
          })
          .catch(reject);
      } else {
        reject({
          message: "У чата нет сообщений"
        });
      }
    });
  }

  async pinStream(): Promise<void> {
    return this.Infra.stream.PinStream(this.id).then(() => {
      this.isPinned = true;

      return;
    });
  }

  async unpinStream(): Promise<void> {
    return this.Infra.stream.UnpinStream(this.id).then(() => {
      this.isPinned = false;

      return;
    });
  }

  leaveStream() {
    return this.Infra.stream.Unsubscribe(this.id);
  }

  addMessages(newMessages: (IMessage | null)[]): void {
    newMessages.forEach(newMessage => {
      if (newMessage) {
        const sameMessageIndex = this.messages.findIndex(
          message => message.id === newMessage.id
        );

        if (sameMessageIndex !== -1) {
          this.messages.splice(sameMessageIndex, 1, newMessage);
        } else {
          this.messages.push(newMessage);
        }
      }
    });
  }

  pushNewMessage(
    newMessage: IMessage,
    users: IUser[],
    user: IUser | null,
    contactList: IUser[],
    byMe: boolean = false
  ) {
    if (!this.messages.some(m => m.id === newMessage.id)) {
      this.lastMessageId = newMessage.id;
      this.lastMessageAuthorName = this.getLastMessageAuthor(
        this.type,
        this.interlocutorId,
        newMessage,
        users,
        user,
        contactList
      );
      this.lastMessageBody = this.getChatBody(newMessage);
      this.lastMessageDate = newMessage.date;
      this.lastMessageDateString = this.getChatTime(
        this.createDate,
        newMessage.date
      );
      if (newMessage.author?.id === user?.id || byMe) {
        this.lastReadMessageId = newMessage.id;
      }
      this.messages.unshift(newMessage);
    }
  }

  setLastReadMessage(messageId: string): void {
    const messages = [...this.messages].reverse();
    let isUnread = false;
    let passedLastUnreadMessage: boolean = false;

    messages.forEach(message => {
      if (message.id <= this.lastReadMessageId) {
        passedLastUnreadMessage = true;
      }
      if (message.id === messageId && passedLastUnreadMessage) {
        isUnread = true;
      }
    });
    if (isUnread) {
      this.lastReadMessageId = messageId;
    }
  }

  getChatTime(createDate: Date, lastMessageDate: Date | undefined): string {
    if (lastMessageDate) {
      return this.formatLastUpdatedAt(lastMessageDate);
    }

    return this.formatLastUpdatedAt(createDate);
  }

  formatLastUpdatedAt(date: Date): string {
    return formatLastUpdatedAt(date);
  }

  getChatBody(lastMessage?: IMessage): string {
    if (lastMessage) {
      return this.getMessageBody(lastMessage);
    }

    return "";
  }

  getMessageBody(message: IMessage, notification?: boolean): string {
    return getMessageBody(message, notification);
  }

  getLastMessageAuthor(
    type: StreamType,
    interlocutorId: string | undefined,
    lastMessage: IMessage | undefined,
    users: IUser[],
    user: IUser | null,
    contactList: IUser[]
  ): string {
    if (lastMessage?.type === Message.Type.SERVICE) {
      return "";
    }
    if (lastMessage?.author) {
      if (lastMessage.guest) {
        return lastMessage.author.renderName;
      }
      const authorId = lastMessage.author.id;

      if (authorId) {
        const messageAuthor = users.find(user => user.id === authorId);

        if (messageAuthor && user) {
          if (user.id === interlocutorId) {
            return "";
          }
          if (user.id === messageAuthor.id) {
            return "Вы";
          }
          if (type !== StreamType.GROUP) {
            return "";
          }
          let name = messageAuthor.renderName;
          const contactUser = contactList.find(
            contact => contact.id === messageAuthor.id
          );

          if (contactUser) {
            name = contactUser.renderName;
          }

          return name;
        }
      }
    }

    return "";
  }

  addParticipants(users: IUser[]) {
    users.forEach(user => {
      const userIndex = this.participants.findIndex(
        p => p.portalCode === user.portalCode
      );

      if (userIndex >= 0) {
        this.participants.splice(userIndex, 1, user);
      } else {
        this.participants.push(user);
      }
    });
  }

  removeParticipant(userId: string) {
    const participantIndex = this.participants.findIndex(p => p.id === userId);

    if (participantIndex >= 0) {
      this.participants.splice(participantIndex, 1);
    }
  }

  setCurrentCall(callData: IStreamCallData | null) {
    this.callData = callData;
  }

  setOncomingMeet(oncomingMeeting: IStreamOncomingMeetData | null) {
    this.oncomingMeeting = oncomingMeeting;
  }

  setMeetData(meetData: IStreamMeetData | null = null) {
    this.meetData = meetData;
  }

  setPinned(pinned: boolean) {
    this.isPinned = pinned;
  }

  async setArchive(archive: boolean, update: boolean = false) {
    if (archive) {
      try {
        if (!update) {
          await this.Infra.stream.ArchiveStream(this.id);
        }
        this.isArchived = true;
      } catch (e) {
        this.isArchived = false;
        throw e;
      }
    } else {
      try {
        if (!update) {
          await this.Infra.stream.UnarchiveStream(this.id);
        }
        this.isArchived = false;
      } catch (e) {
        this.isArchived = true;
        throw e;
      }
    }
  }

  editMessage(
    message: IMessage,
    users: IUser[],
    user: IUser | null,
    contactList: IUser[]
  ) {
    const messageIndex = this.messages.findIndex(m => m.id === message.id);

    if (messageIndex !== -1) {
      this.messages.splice(messageIndex, 1, message);
      if (messageIndex === 0) {
        const newLastMessage = this.messages[0];

        if (newLastMessage) {
          this.lastMessageId = newLastMessage.id;
          this.lastMessageDate = newLastMessage.date;
          this.lastMessageBody = this.getChatBody(newLastMessage);
          this.lastMessageAuthorName = this.getLastMessageAuthor(
            this.type,
            this.interlocutorId,
            newLastMessage,
            users,
            user,
            contactList
          );
          this.lastMessageDateString = this.getChatTime(
            this.createDate,
            newLastMessage.date
          );
        }
      }
    }
  }

  deleteMessage(
    messageId: string,
    users: IUser[],
    user: IUser | null,
    contactList: IUser[]
  ) {
    const messageIndex = this.messages.findIndex(m => m.id === messageId);

    if (messageIndex !== -1) {
      this.messages.splice(messageIndex, 1);
      if (messageIndex === 0) {
        const newLastMessage = this.messages[0];

        if (newLastMessage) {
          this.lastMessageId = newLastMessage.id;
          this.lastMessageDate = newLastMessage.date;
          this.lastMessageBody = this.getChatBody(newLastMessage);
          this.lastMessageAuthorName = this.getLastMessageAuthor(
            this.type,
            this.interlocutorId,
            newLastMessage,
            users,
            user,
            contactList
          );
          this.lastMessageDateString = this.getChatTime(
            this.createDate,
            newLastMessage.date
          );
        } else if (!this.messages.length) {
          this.lastMessageId = null;
          this.lastMessageDate = this.createDate;
          this.lastMessageBody = null;
          this.lastMessageAuthorName = null;
          this.lastMessageDateString = this.getChatTime(
            this.createDate,
            undefined
          );
        }
      }
    }
  }
}

export interface IMessage {
  id: string;
  isByMe: boolean;
  body: string;
  type: Message.Type;
  author?: IUser;
  contact?: IUser;
  date: Date;
  pending?: boolean;
  error?: boolean;
  entities: IMessageEntity[];
  documents?: IAttachment[];
  images: IAttachment[];
  forward: IMessage | null;
  reply: IMessage | null;
  streamId: string;
  isForwardChannel?: boolean;
  streamName: string;
  streamType: StreamType;
  systemType?: Message.ServiceType;
  guest?: boolean;
  callDuration?: number;
  edited: boolean;
}

export interface IAttachment {
  name: string;
  uuid: string;
  thumb?: {
    id: string;
    type: string;
  };
  progress: number;
  size: number;
  type: string;
  loading?: boolean;
  base?: string;
  file?: File;
  error?: boolean;
}

export interface IStreamStore {
  webSocket: WebSocket | null;
  streamList: Stream[];
  errorHandler: Function | null;
  disconnectHandler: Function | null;
  isLoading: boolean;
  isFullLoading: boolean;
  isWebSocketConnecting: boolean;
  isWebSocketOnline: boolean;
  users: IUser[];
}

export interface IMessageEntity {
  type: Type;
  start: number;
  length: number;
  userId?: string;
  streamId?: string;
  value?: string;
  lang?: string;
}

export interface IStreamCallData {
  roomId: string;
}

export interface IStreamOncomingMeetData {
  meetId: string;
  subject: string;
  date: Date;
}

export interface IStreamMeetData {
  subject: string;
  url: string;
  id: string;
  status: PlannedMeetStatus;
}

export namespace Centrifuge {
  export interface Notification {
    type: NotificationType;
    invite?: Centrifuge.IStreamNotification;
    sub_unsub?: Centrifuge.ISubscription;
    read?: Centrifuge.IRead;
    stream?: Centrifuge.IStreamNotification;
    profile?: Centrifuge.IProfileNotification;
    user?: Centrifuge.IUserNotification;
    contact?: Centrifuge.IContact;
    call?: Centrifuge.ICall;
    message?: Centrifuge.IMessage;
    oncoming_meet?: Centrifuge.IOncomingMeet;
    presence?: Centrifuge.IPresence;
    archive_stream?: Centrifuge.IArchive;
    deleted_message?: Centrifuge.IDeleteMessage;
    edited_message?: Centrifuge.IEditMessage;
    pin_unpin_stream?: Centrifuge.IPinUnpinStream;
  }

  export interface ISubscription {
    stream_id: string;
    user_id: string;
    user?: Centrifuge.IUser;
  }

  export interface IRead {
    stream_id: string;
    message_id: string;
  }

  export interface IStreamNotification {
    stream_id: string;
    stream?: Centrifuge.ISubscribedStream;
    active_meet?: {
      stream_id: string;
      room_id: string;
    };
    ongoing_meet: {
      stream_id: string;
      meet_id: string;
      date: string;
      subject: string;
    };
  }

  export interface ISubscribedStream {
    stream?: Centrifuge.IStream;
    my_received_message: string;
    my_read_message: string;
    interlocutor_received_message: string;
    interlocutor_read_message: string;
    last_message_id: string;
    last_message: string;
    bot_access_read_messages: boolean;
  }

  export interface IStream {
    id: string;
    username: string;
    title: string;
    description?: string;
    visible: OuterStream.Visible;
    type: OuterStream.Type;
    logo?: Centrifuge.IAvatar;
    created_at?: string;
    is_saved_messages?: boolean;
    meta?: Centrifuge.IStreamMeta;
    system_type: "unspecified" | "super_channel";
    file_storage_link: string;
  }

  export interface IUser {
    email: string;
    first_name?: string;
    id: string;
    username: string;
    phone: string;
    department: string;
    position: string;
    description: string;
    last_name?: string;
    middle_name?: string;
    portal_code: string;
    status: UserStatus;
    is_bot: boolean;
    is_super_admin: boolean;
    profile_id: string;
    avatar?: Centrifuge.IAvatar;
  }

  export interface IPerson {
    birth_date: string;
    email: string;
    name: string;
    portal_code: number;
    status: UserStatus;
    surname: string;
    user_id: string;
    avatar?: Centrifuge.IAvatar;
    patronym: string;
    position: string;
    work_number: string;
    mobile_number: string;
    contact_number: string;
    username: string;
    birthdate: string;
  }

  export interface IAvatar {
    id: string;
    filename: string;
    mime: string;
    size: number;
    width: number;
    height: number;
    original: string;
    photo_small_url?: string;
    photo_large_url?: string;
  }

  export interface IStreamMeta {
    owner: string;
    interlocutor: string;
    updated_at: string;
    pinned: boolean;
    deleted: boolean;
    users_count: number;
    archived: boolean;
  }

  export interface IProfileNotification {
    userid: string;
    profile?: IProfile;
  }

  export interface IProfile {
    user?: Centrifuge.IUser;
    phone: string;
    email: string;
    employee_email: string;
    created_at?: string;
    updated_at?: string;
    language: string;
    theme: AppTheme;
    timezone: string;
    sid: string;
    device_id: string;
    session_id: string;
    portal_code: string;
  }

  export interface IUserNotification {
    user_id: string;
    user?: Centrifuge.IUser;
  }

  export interface IContact {
    portal_code: number;
    person?: Centrifuge.IPerson;
  }

  export interface ICall {
    room_id: string;
    call_type: CallType;
    user_id: string;
    device_id?: string;
    subject: string;
    participants: Centrifuge.IParticipant[];
    participants_count: number;
    is_group?: boolean;
  }

  export interface IParticipant {
    avatar_url: string;
    id: string;
    name: string;
  }

  export interface IMessage {
    id: string;
    pid: string;
    stream_id: string;
    user_id: string;
    content?: string;
    created_at?: string;
    updated_at?: string;
    attach?: {
      document?: Centrifuge.IAttachment;
      gallery?: Centrifuge.IGallery;
      contact?: Centrifuge.IUser;
      file_list?: Centrifuge.IGallery;
      call_duration?: string;
    };
    entities?: Centrifuge.IEntity[];
    type: Message.Type;
    service_type: Message.ServiceType;
    reply_to_message_id: string;
    original_message?: Centrifuge.IMessage;
    forward_from?: Centrifuge.IUser;
    forward_sender_name: string;
    forward_from_stream?: Centrifuge.IStream;
    forward_message_id: string;
    edited: boolean;
  }

  export interface IEntity {
    type: MessageEntity.Type;
    offset: number;
    length: number;
    user_id: string;
    stream_id: string;
    value: string;
    language: string;
  }

  export interface IGallery {
    attachments: Centrifuge.IAttachment[];
  }

  export interface IAttachment {
    file?: Centrifuge.IFile;
    caption: string;
    entities?: Centrifuge.IEntity[];
  }

  export interface IFile {
    filename: string;
    id: string;
    meta: {
      width: number;
      height: number;
      waveform: string;
      duration: number;
      thumb: string;
    };
    mime: string;
    size: number;
    type: OuterFile.Type;
  }

  export interface IOncomingMeet {
    stream_id: string;
    meet_id: string;
    date: string;
    subject: string;
    remind_in: number;
  }

  export interface IPresence {
    id: string;
    status: UserPresenceStatus;
  }

  export interface IArchive {
    stream_id: string;
    archive: boolean;
  }

  export interface IDeleteMessage {
    message_id: string;
    stream_id: string;
  }

  export interface IEditMessage {
    message: Centrifuge.IMessage;
  }

  export interface IPinUnpinStream {
    stream_id: string;
    pinned: boolean;
  }
}

/* eslint-disable no-unused-vars */
export enum InitMode {
  Main,
  Reload,
  Background,
  Embedded
}
