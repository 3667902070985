// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-menu {
  position: relative;
  z-index: 3;
}

.dropdown-menu .tn-dropdown__container {
  min-width: unset;
}

.dropdown-menu .tn-popover__wrapper_floating {
  position: relative !important;
}

/* dark theme */
.dark-theme .tn-dropdown__container {
  box-shadow: 0 0 8px rgba(0, 0, 0, .2);
}
`, "",{"version":3,"sources":["webpack://./src/components/dropdown-menu/dropdown-menu.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA,eAAe;AACf;EACE,qCAAqC;AACvC","sourcesContent":[".dropdown-menu {\n  position: relative;\n  z-index: 3;\n}\n\n.dropdown-menu .tn-dropdown__container {\n  min-width: unset;\n}\n\n.dropdown-menu .tn-popover__wrapper_floating {\n  position: relative !important;\n}\n\n/* dark theme */\n.dark-theme .tn-dropdown__container {\n  box-shadow: 0 0 8px rgba(0, 0, 0, .2);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
