<template>
  <svg
    class="svg-icon-pack"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    style="position: fixed; overflow: hidden; width: 1px; height: 1px; top: 0; left: -1px;"
  >
    <symbol id="icon--sidebar-close" viewBox="0 0 24 24">
      <path
        d="M7 2C4.23 2 2 4.23 2 7L2 17C2 19.76 4.23 22 7 22L17 22C19.76 22 22 19.76 22 17L22 7C22 4.23 19.76 2 17 2L7 2ZM7 3.5L8 3.5L8 20.5L7 20.5C5.06 20.5 3.5 18.93 3.5 17L3.5 7C3.5 5.06 5.06 3.5 7 3.5ZM17 20.5L9.5 20.5L9.5 3.5L17 3.5C18.93 3.5 20.5 5.06 20.5 7L20.5 17C20.5 18.93 18.93 20.5 17 20.5ZM16.69 6.49C16.96 6.19 17.44 6.16 17.75 6.44C18.05 6.71 18.08 7.19 17.8 7.5L13.48 12.3C13.38 12.41 13.39 12.57 13.5 12.66L17.74 16.43C18.05 16.71 18.08 17.18 17.81 17.49C17.53 17.8 17.06 17.83 16.75 17.56L12.5 13.78C11.77 13.13 11.71 12.02 12.36 11.3L16.69 6.49Z"
        fill="currentColor"
        fill-rule="evenodd"
      />
    </symbol>
    <symbol id="icon--sidebar-open" viewBox="0 0 24 24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17 3.5H7C5.067 3.5 3.5 5.067 3.5 7V17C3.5 18.933 5.067 20.5 7 20.5H17C18.933 20.5 20.5 18.933 20.5 17V7C20.5 5.067 18.933 3.5 17 3.5ZM7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7Z"
        fill="currentColor"
      />
      <path d="M8 3H9.5V21H8V3Z" fill="currentColor" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.4983 6.44254C12.8062 6.16544 13.2804 6.1904 13.5575 6.49828L17.8844 11.3059C18.5337 12.0275 18.4718 13.1397 17.7462 13.7846L13.4983 17.5606C13.1887 17.8358 12.7146 17.8079 12.4395 17.4983C12.1643 17.1887 12.1921 16.7146 12.5017 16.4395L16.7497 12.6635C16.8533 12.5714 16.8622 12.4125 16.7694 12.3094L12.4425 7.50173C12.1654 7.19385 12.1904 6.71963 12.4983 6.44254Z"
        fill="currentColor"
      />
    </symbol>
    <symbol id="icon--tasks-light" viewBox="0 0 24 24">
      <path
        d="M16.75 2.12C16.28 1.42 15.49 1 14.64 1L9.35 1C8.5 1 7.71 1.42 7.25 2.12C7.09 2.35 6.83 2.49 6.54 2.52C4.55 2.74 3 4.44 3 6.5L3 18.5C3 20.7 4.79 22.5 7 22.5L17 22.5C19.2 22.5 21 20.7 21 18.5L21 6.5C21 4.44 19.44 2.74 17.45 2.52C17.16 2.49 16.9 2.35 16.75 2.12ZM17 4C16.68 4 16.39 3.84 16.22 3.58L16 3.25C15.68 2.78 15.16 2.5 14.59 2.5L9.4 2.5C8.83 2.5 8.31 2.78 8 3.25L7.77 3.58C7.6 3.84 7.31 4 7 4C5.61 4 4.5 5.11 4.5 6.5L4.5 18.5C4.5 19.88 5.61 21 7 21L17 21C18.38 21 19.5 19.88 19.5 18.5L19.5 6.5C19.5 5.11 18.38 4 17 4ZM6.75 7C6.33 7 6 7.33 6 7.75C6 8.16 6.33 8.5 6.75 8.5L17.25 8.5C17.66 8.5 18 8.16 18 7.75C18 7.33 17.66 7 17.25 7L6.75 7ZM6.75 11.75C6.33 11.75 6 12.08 6 12.5C6 12.91 6.33 13.25 6.75 13.25L13.75 13.25C14.16 13.25 14.5 12.91 14.5 12.5C14.5 12.08 14.16 11.75 13.75 11.75L6.75 11.75ZM6 17.25C6 16.83 6.33 16.5 6.75 16.5L11.25 16.5C11.66 16.5 12 16.83 12 17.25C12 17.66 11.66 18 11.25 18L6.75 18C6.33 18 6 17.66 6 17.25Z"
        fill="currentColor"
        fill-rule="evenodd"
      />
    </symbol>
    <symbol id="icon--check-filled" viewBox="0 0 24 24">
      <path
        clip-rule="evenodd"
        fill-rule="evenodd"
        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM17.6783 9.7348C18.0841 9.3602 18.1094 8.72754 17.7348 8.32172C17.3602 7.9159 16.7275 7.89059 16.3217 8.2652L10.4754 13.6618L7.65079 11.2407C7.23147 10.8813 6.60017 10.9299 6.24074 11.3492C5.88132 11.7685 5.92988 12.3998 6.34921 12.7593L9.84921 15.7593L10.5246 16.3382L11.1783 15.7348L17.6783 9.7348Z"
        fill="currentColor"
      />
    </symbol>
    <symbol id="icon--tasks-personal" viewBox="0 0 24 24">
      <path
        d="M14.64 1C15.49 1 16.28 1.42 16.75 2.12C16.9 2.35 17.16 2.49 17.44 2.52C19.44 2.74 21 4.44 21 6.5L21 9.25C21 9.66 20.66 10 20.25 10C19.83 10 19.5 9.66 19.5 9.25L19.5 6.5C19.5 5.11 18.38 4 17 4C16.68 4 16.39 3.84 16.22 3.58L16 3.25C15.68 2.78 15.16 2.5 14.59 2.5L9.4 2.5C8.83 2.5 8.31 2.78 8 3.25L7.77 3.58C7.6 3.84 7.31 4 7 4C5.61 4 4.5 5.11 4.5 6.5L4.5 18.5C4.5 19.88 5.61 21 7 21L10.82 21C11.24 21 11.57 21.33 11.57 21.75C11.57 22.16 11.24 22.5 10.82 22.5L7 22.5C4.79 22.5 3 20.7 3 18.5L3 6.5C3 4.44 4.55 2.74 6.55 2.52C6.83 2.49 7.09 2.35 7.25 2.12C7.71 1.42 8.5 1 9.35 1L14.64 1ZM6.75 7C6.33 7 6 7.33 6 7.75C6 8.16 6.33 8.5 6.75 8.5L17.25 8.5C17.66 8.5 18 8.16 18 7.75C18 7.33 17.66 7 17.25 7L6.75 7ZM18.5 16.5C19.88 16.5 21 15.38 21 14C21 12.61 19.88 11.5 18.5 11.5C17.11 11.5 16 12.61 16 14C16 15.38 17.11 16.5 18.5 16.5ZM6 12.5C6 12.08 6.33 11.75 6.75 11.75L13.75 11.75C14.16 11.75 14.5 12.08 14.5 12.5C14.5 12.91 14.16 13.25 13.75 13.25L6.75 13.25C6.33 13.25 6 12.91 6 12.5ZM6.75 16.5C6.33 16.5 6 16.83 6 17.25C6 17.66 6.33 18 6.75 18L11.25 18C11.66 18 12 17.66 12 17.25C12 16.83 11.66 16.5 11.25 16.5L6.75 16.5ZM15.21 22.5C14.03 22.5 13.08 21.45 13.64 20.41C14.56 18.68 16.39 17.5 18.5 17.5C20.6 17.5 22.43 18.68 23.35 20.41C23.91 21.45 22.96 22.5 21.78 22.5L15.21 22.5Z"
        fill="currentColor"
        fill-rule="evenodd"
      />
    </symbol>
    <symbol id="icon--exclamation-mark" viewBox="0 0 12 12">
      <path
        d="M7.05 6.74C7.03 7.26 6.6 7.67 6.08 7.67C5.55 7.67 5.12 7.26 5.1 6.74L4.85 1.64C4.82 1.07 5.27 0.59 5.85 0.59L6.29 0.59C6.87 0.59 7.32 1.07 7.29 1.64L7.05 6.74Z"
        fill="currentColor"
        fill-rule="evenodd"
      />
      <path d="M6.86 10.43C6.64 10.64 6.37 10.75 6.06 10.75C5.75 10.75 5.49 10.64 5.26 10.43C5.05 10.22 4.94 9.97 4.94 9.69C4.94 9.4 5.05 9.15 5.26 8.94C5.49 8.72 5.75 8.62 6.06 8.62C6.37 8.62 6.64 8.72 6.86 8.94C7.08 9.15 7.2 9.4 7.2 9.69C7.2 9.97 7.08 10.22 6.86 10.43Z" fill="currentColor" fill-rule="evenodd" />
    </symbol>
  </svg>
</template>
<script setup>
</script>
