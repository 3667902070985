import { ITNToast } from "@life_uikit/uikit/interfaces";
import { Profile } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/profile_pb";
import { RouteLocationRaw } from "vue-router";
import { MutationTree } from "vuex";

import { nullUuid } from "@/common/constants";
import { IPopup } from "@/components/popup/i-popup";
import Infrastructure from "@/infrastructure";
import { StreamService } from "@/service";
import {
  IMutationToast,
  IState,
  ITimezone,
  IUser,
  EmailStatus,
  IColleagues
} from "@/store/i-store";
import { Centrifuge, IMessage, Stream } from "@/store/modules/stream/i-stream";
import {
  profileAdapter,
  profileNotificationAdapter
} from "@/store/store-adapters";
import { IApp, ISite } from "@/views/services/i-services";

const Infra = new Infrastructure();
const streamService = new StreamService();

export default {
  setUser(state, user: Profile.AsObject) {
    const updatedUser = profileAdapter(user);


    const subordinates = state.user?.subordinates;
    const coworkers = state.user?.coworkers;

    if (updatedUser) {
      state.user = {...state.user, ...updatedUser};
      state.emailStatus = user.email ? EmailStatus.Confirmed : EmailStatus.NotSet;
      state.deviceID = user.deviceid;

      if (subordinates?.length) {
        state.user.subordinates = subordinates;
      }
      if (coworkers?.length) {
        state.user.coworkers = coworkers;
      }
    }

  },
  setUserPersonData(state, person: IUser) {
    if (state.user) {
      state.user = {
        ...state.user,
        ...person
      };
    }
  },
  setUserColleagues(state, colleagues: IColleagues) {
    if (state.user) {
      state.user.coworkers = colleagues.coworkers;
      state.user.admLeader = colleagues.admLeader;
      state.user.funcLeader = colleagues.funcLeader;
      state.user.subordinates = colleagues.subordinates;
    }
  },
  setUserAvatarLink(state, url: string) {
    if (state.user?.avatar) state.user.avatar.url = url;
  },
  setCurrentChatId(state, streamId: string | null) {
    state.currentChatId = streamId;
  },
  setColleagues(state, colleagues: IUser[]) {
    state.colleagues = colleagues;
  },
  setSiteList(state, siteList: ISite[]) {
    state.siteList = [...siteList];
  },
  removeContact(state, portalCode: number) {
    if (state.contactList) {
      const contactIndex = state.contactList.findIndex(
        c => c.portalCode === portalCode
      );

      if (contactIndex !== -1) {
        state.contactList.splice(contactIndex, 1);
      }
    }
  },
  setShareFile(
    state,
    payload: {
      file: File | null;
      asFile: boolean | undefined;
    } | null
  ) {
    state.shareFile = payload?.file || null;
    state.isShareFileFile = !!payload?.asFile;
  },
  setForwardMessage(state, message: IMessage | null) {
    state.forwardMessage = message;
  },
  openFeedbackForm(state, payload?: { type: string; context?: string }) {
    state.feedbackForm = payload ? payload : true;
  },
  closeFeedbackForm(state) {
    state.feedbackForm = false;
  },
  setTriggerTutorialsFunction(state, functionItem: Function | null) {
    state.tutorialTriggerFunction = functionItem;
  },
  triggerTutorials(state) {
    if (state.tutorialTriggerFunction) {
      state.tutorialTriggerFunction();
    }
  },
  addScrollPosition(
    state,
    payload: {
      uuid: string;
      position: number;
    }
  ) {
    const position = state.chatScrollPosition.find(
      pos => pos.uuid === payload.uuid
    );

    if (position) {
      position.position = payload.position;
    } else {
      state.chatScrollPosition.push(payload);
    }
  },
  updateContact(state, notification: Centrifuge.Notification) {
    const contact = notification.contact;
    const list = state.contactList || [];
    const contactIndex = list.findIndex(
      c => contact && c.portalCode === contact.portal_code
    );

    if (contactIndex !== -1) {
      if (contact?.person) {
        const user = streamService.personAdapter(
          streamService.notificationPersonAdapterRaw(contact.person)
        );

        list.splice(contactIndex, 1, user);
        const stream = state["streamStore"].streamList.find(
          (s: Stream) => s.interlocutorId === contact.person?.portal_code
        );

        if (stream) {
          stream.title = user.renderName;
        }
      } else if (contact) {
        list.splice(contactIndex, 1);
      }
    } else if (contact?.person) {
      list.push(
        streamService.personAdapter(
          streamService.notificationPersonAdapterRaw(contact.person)
        )
      );
    }
  },
  async updateProfile(state, notification: Centrifuge.Notification) {
    const profile = notification.profile;

    if (profile?.profile) {
      state.user = profileNotificationAdapter(profile.profile);
    }
  },
  setFocusApp(state, focused: boolean) {
    state.isAppFocused = focused;
  },
  closeToast(state, index: number) {
    const foundNotificationIndex = state.toasts.findIndex(
      n => n.creationTime === index
    );

    if (foundNotificationIndex !== -1) {
      state.toasts.splice(foundNotificationIndex, 1);
    }
  },
  showPopup(state, payload: IPopup) {
    const popup = { ...payload, index: Date.now(), hidden: true };

    state.popupList.push(popup);

    setTimeout(() => {
      const popupIndex = state.popupList.findIndex(
        p => p.index === popup.index
      );

      if (popupIndex !== -1) {
        state.popupList.splice(popupIndex, 1, { ...popup, hidden: false });
      }
    }, 10);
  },
  closePopup(state, index: number) {
    const foundPopup = state.popupList.findIndex(n => n.index === index);

    if (foundPopup !== -1) {
      const closeHandler = state.popupList[foundPopup]?.closeHandler;

      if (closeHandler) {
        closeHandler();
      }
      state.popupList[foundPopup].hidden = true;
      setTimeout(() => {
        state.popupList.splice(foundPopup, 1);
      }, 300);
    }
  },
  toast(state, payload: IMutationToast | string) {
    const newToast: ITNToast =
      typeof payload === "string"
        ? {
            text: payload,
            duration: 3000,
            creationTime: Date.now(),
            type: "info"
          }
        : {
            ...payload,
            duration: payload.duration ? payload.duration : 3000,
            creationTime: Date.now()
          };

    state.toasts.unshift(newToast);
  },
  appendWidget(state, app: IApp) {
    state.widgetList.push({
      uuid: app.uuid,
      url: app.widgetUrl,
      name: app.title
    });
  },
  addFile(
    state,
    file: {
      uuid: string;
      name: string;
      base?: string;
      url?: string;
    }
  ) {
    const fileIndex = state.fileList.findIndex(
      item => item.uuid === file.uuid && file.uuid !== nullUuid
    );

    if (fileIndex === -1) {
      state.fileList.push(file);
    } else {
      state.fileList.splice(fileIndex, 1, file);
    }
  },
  setPageState(
    { pageStates },
    state: { key: string; value: string; params?: Record<string, any> }
  ) {
    pageStates[state.key] = {
      value: state.value,
      time: Date.now(),
      params: state.params ? { ...state.params } : undefined
    };
  },
  removePageState({ pageStates }, stateName: string) {
    if (pageStates[stateName]) {
      pageStates[stateName] = undefined;
    }
  },
  closeApp(state, id: string) {
    const runningAppIndex = state.runningApps.findIndex(a => a.id === id);

    if (runningAppIndex !== -1) {
      state.runningApps.splice(runningAppIndex, 1);
    }
  },
  saveTrackerPage(state, location: RouteLocationRaw | null) {
    state.lastTrackerPageLocation = location;
  },
  getMobile(state) {
    if (window.innerWidth < 768) {
      state.mobileWindowHeight = window.innerHeight;
      state.isMobile = true;
    } else {
      state.isMobile = false;
      state.mobileWindowHeight = 0;
    }
  },
  async loadContacts(state) {
    state.contactListLoad = true;
    try {
      const contacts = await Infra.contacts.GetContactsList();

      state.contactList = contacts.map(user =>
        streamService.personAdapter(user)
      );
    } catch {
      state.contactList = null;
    }
    state.contactListLoad = false;
  },
  setIsBrowser(state, isBrowser: boolean) {
    state.isBrowser = isBrowser;
  },
  setIsOnline(state, isOnline: boolean) {
    state.isOnline = isOnline;
  },
  setForceLeavePageHandler(state, handler: Function | null) {
    state.forceLeavePageHandler = handler;
  },
  setUpdateStatus(
    state,
    status: {
      actual: string;
      appUrl: string;
      force: boolean;
    } | null
  ) {
    state.updateStatus = status;
  },
  setTimezones(state, timezones: ITimezone[]) {
    state.timezones = timezones;
  }
} as MutationTree<IState>;
