import { INavigation } from "@/components/sidebar/i-sidebar";

const navigationList: INavigation[] = [
  {
    name: "Life",
    path: "/",
    iconName: "home-life"
  },
  {
    name: "Чаты",
    path: "/chat",
    iconName: "message-large"
  },
  {
    name: "Контакты",
    path: "/contacts",
    iconName: "address-book"
  },
  {
    name: "Сервисы",
    path: "/services",
    iconName: "applications"
  },
  {
    name: "Встречи",
    path: "/meets",
    iconName: "meets"
  }
];

export const getMainNavigationList = (
  isTrackerEnabled: boolean
): INavigation[] =>
  isTrackerEnabled
    ? [
        ...navigationList,
        {
          name: "Задачи",
          path: "/tracker",
          iconName: "check-filled"
        }
      ]
    : navigationList;
