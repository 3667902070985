import { AttachFile, AttachGallery } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/attachment_pb";
import { PlannedMeetRole, Role } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/meet_pb";
import { MessageEntity } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/message_entity_pb";

import { nullUuid } from "@/common/constants";
import { IMessageEntity } from "@/store/modules/stream/i-stream";

export const setPlannedMeetRole = (roles: {
  portalCode: string;
  role: Role;
}[]): PlannedMeetRole[] => roles.map(role => {
  const meetRole = new PlannedMeetRole();

  meetRole.setRole(role.role);
  meetRole.setPortalcode(Number(role.portalCode));

  return meetRole;
});

export const getBase64 = (buffer: Uint8Array): string => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;

  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return window.btoa(binary);
};

export const messageEntityAdapter = (entity: IMessageEntity): MessageEntity => {
  const newEntity = new MessageEntity();

  newEntity.setType(entity.type);
  newEntity.setOffset(entity.start);
  newEntity.setLength(entity.length);
  entity.streamId && newEntity.setStreamid(entity.streamId);
  entity.lang && newEntity.setLanguage(entity.lang);
  entity.userId && newEntity.setUserid(entity.userId);
  entity.value && newEntity.setValue(entity.value);

  return newEntity;
};

export const fileAttachmentAdapter = (attachmentId: string): AttachFile => {
  const newAttachment = new AttachFile();

  newAttachment.setFileid(attachmentId || nullUuid);

  return newAttachment;
};

export const attachGalleryAdapter = (images: string[]): AttachGallery => {
  const gallery = new AttachGallery();

  gallery.setAttachmentsList(images.map(img => fileAttachmentAdapter(img)));

  return gallery;
};
