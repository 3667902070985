import { ITNToast } from "@life_uikit/uikit/interfaces";
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  watch
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import { LSNames, uuidFragmentRegex } from "@/common/constants";
import Avatar from "@/components/avatar/avatar";
import HeaderComponent from "@/components/header/header";
import { IPopup } from "@/components/popup/i-popup";
import Popup from "@/components/popup/popup";
import PopupList from "@/components/popup-list/popup-list";
import { ReportType } from "@/components/report-form/i-report-form";
import ReportForm from "@/components/report-form/report-form";
import Service from "@/components/service/service";
import Sidebar from "@/components/sidebar/sidebar";
import Sprite from "@/components/sprite/sprite.vue";
import UpdateOverlay from "@/components/update-overlay/update-overlay";
import { useError } from "@/composables/error";
import { useInfrastructure } from "@/composables/infrastructure";
import { useLayout } from "@/composables/layout";
import { usePopup } from "@/composables/popup";
import { useService } from "@/composables/service";
import { Electron } from "@/infrastructure/electron";
import { clearDB } from "@/infrastructure/indexed-db/indexed-db";
import OfflinePopup from "@/layouts/components/offline-popup/offline-popup.vue";
import { key } from "@/store";
import { IIncomingCall, IRunningApp } from "@/store/i-store";
import { InitMode } from "@/store/modules/stream/i-stream";

import MeetCall from "./components/meet-call/meet-call";

export default defineComponent({
  name: "Layout",
  components: {
    Sidebar,
    HeaderComponent,
    PopupList,
    ReportForm,
    Service,
    Popup,
    Avatar,
    MeetCall,
    UpdateOverlay,
    OfflinePopup,
    Sprite
  },
  setup: () => {
    const $store = useStore(key);
    const $route = useRoute();
    const $router = useRouter();
    const { $service } = useService();
    const $infra = useInfrastructure();
    const $error = useError();
    const { $popup, $closePopup } = usePopup();
    const { isSimpleLayout, isNoHeader } = useLayout();

    const maxAppsValue = ref<string>("2");
    const callsLoading = ref<string[]>([]);
    const ringtoneCheckBoxValue = ref<boolean>(false);
    const payloadString = ref<string>("");
    const isShortLinkCreating = ref<boolean>(false);
    const isUpdateDownloading = ref<boolean>(false);
    const isUpdateError = ref<boolean>(false);
    const showTooltipValue = ref<boolean>(false);
    const updateDownloadProgress = ref<number>(0);
    const isPopupShow = ref<boolean>(false);
    const isPWA = ref<boolean>(false);

    onMounted(async () => {
      if (isEmbedded.value) return;
      await initializeApplication();
      if (!isBrowser.value) {
        initializeElectron();
      } else {
        initializeWeb();
      }
    });

    let savedDeepLink: { type: string; appId: string; payload: string } | null =
      null;

    const isMobile = computed<boolean>(() => $store.state.isMobile);
    const isOnline = computed<boolean>(() => $store.state.isOnline);
    const isCentrifugeOnline = computed<boolean>(
      () => $store.state.streamStore.isWebSocketOnline
    );
    const isOnboarding = computed(() => $route.name === "onboarding");
    const toastList = computed<ITNToast[]>(() => $store.state.toasts);
    const popupList = computed<IPopup[]>(() => $store.state.popupList);
    const devMode = computed<boolean>(
      () => $store.state.settingsStore.developerMode
    );
    const toastNoPadding = computed<boolean>(
      () =>
        !!isPopupShow.value ||
        [
          "app",
          "chat-list",
          "chat",
          "contacts",
          "user-page",
          "404",
          "auth"
        ].includes($route.name as string)
    );
    const runningApps = computed<IRunningApp[]>(() => $store.state.runningApps);
    const isAppFocused = computed<boolean>(() => $store.state.isAppFocused);
    const isSubpage = computed<boolean>(
      () => !["index", "services"].includes($route.name as string)
    );
    const incomingCallWindows = computed<IIncomingCall[]>(
      () => $store.state.incomingCallWindows
    );
    const feedbackForm = computed<boolean | { type: string; context?: string }>(
      () => $store.state.feedbackForm
    );
    const isBrowser = computed<boolean>(() => $store.state.isBrowser);
    const isWaitingCallRejected = computed<boolean>(
      () => $store.state.isWaitingCallRejected
    );
    const waitingCallWindow = computed<IIncomingCall | null>(
      () => $store.state.waitingCallWindow
    );
    const maxAppsWarn = computed<string>(() => {
      const value = Number(maxAppsValue.value);

      if (value && !isNaN(value) && Math.floor(value) > 0) {
        return "";
      }

      return "Введите целое значение от 1 и более";
    });
    const isUpdateRequired = computed<boolean>(
      () => !!$store.state.updateStatus
    );
    const isUpdateHard = computed<boolean>(
      () => !!$store.state.updateStatus?.force
    );
    const isEmbedded = computed<boolean>(
      () => window.isEmbedded || $route.path.includes("embedded")
    );
    const classList = computed<string[]>(() => {
      if (isEmbedded.value) return [];

      const list: string[] = [];

      if (!isAppFocused.value && !isBrowser.value) {
        list.push("layout_no-focus");
      }
      if (!isBrowser.value) {
        list.push("layout_app");
      }
      if (isPWA.value) {
        list.push("layout_pwa");
      }

      return list;
    });

    watch(
      () => $route.fullPath,
      (to, from) => {
        if (to.startsWith("/app/")) {
          const toId = (to.replace("/app/", "").match(uuidFragmentRegex) ||
            [])[0];
          const fromId = (from.replace("/app/", "").match(uuidFragmentRegex) ||
            [])[0];

          if (toId) {
            const runningApp = runningApps.value.find(ra => ra.id === toId);

            if (runningApp?.query && toId !== fromId) {
              $router.push({ query: runningApp.query });
            }
            $store.dispatch("openApp", {
              id: toId,
              query: runningApp?.query
            });
          }
        }
      }
    );
    watch(
      () => $route.name,
      (to, from) => {
        if (from === "auth" && to === "index" && savedDeepLink) {
          deepLinkHandler(savedDeepLink);
          savedDeepLink = null;
        }
      }
    );
    watch(
      () => toastList.value.length,
      (to, from) => {
        if (to > from) getPopupShow();
      },
      { deep: true }
    );
    watch(
      () => isOnline.value,
      (to, from) => {
        if (to && !from) {
          becameOnlineHandler();
        }
      }
    );
    watch(
      () => $store.state.isAppFocused,
      to => {
        if (to) {
          $store.dispatch("updateData");
        }
      }
    );

    const initializeElectron = () => {
      Electron().ipcRenderer.send("reset-asked-for-update");
      Electron().ipcRenderer.on("notification-clicked", (_, data) => {
        if (data.type === "message" && data.payload?.streamId) {
          $router.push({
            name: "chat-page",
            params: {
              id: data.payload.streamId
            }
          });
        } else if (data.type === "oncoming-meet" && data.payload) {
          $router.push({
            name: "meets",
            params: {
              meetId: data.payload.meet_id
            }
          });
        }
      });
      Electron().ipcRenderer.on("handle-deep-link", async (_, url) => {
        const data = $service.getRouteFromAppLink(url);

        if ($route.name === "auth") {
          savedDeepLink = data;

          return;
        }
        void deepLinkHandler(data);
      });
      Electron().ipcRenderer.on("changed-focus-state", (_, focused) => {
        $store.commit("setFocusApp", focused);
      });
      Electron().ipcRenderer.on("download-progress", (_, progress) => {
        if (isUpdateDownloading.value) {
          updateDownloadProgress.value = progress;
        }
      });
      Electron().ipcRenderer.on("update-error", () => {
        if (isUpdateDownloading.value) {
          isUpdateDownloading.value = false;
          isUpdateError.value = true;
        }
      });
      Electron().ipcRenderer.on("update-cancelled", () => {
        if (isUpdateDownloading.value) {
          isUpdateDownloading.value = false;
          updateDownloadProgress.value = 0;
          isUpdateError.value = false;
        }
      });
      Electron().ipcRenderer.on("force-auth", async () => {
        const routeName = $route.name;

        await $router.push({
          name: "auth",
          query: {
            forceAuth: "true"
          }
        });
        if (routeName === "auth") {
          location.reload();
        }
      });
      Electron().ipcRenderer.on("check-version", async () => {
        localStorage.removeItem(LSNames.DelayNewVersion);
        try {
          const config = await $infra.setting.Config();
          const checkResponse = $service.checkVersion(config);

          if (checkResponse) {
            $popup({
              title: "Проверить обновления",
              text:
                "Установлена последняя версия приложения TN Life " +
                VUE_APP_VERSION,
              index: Date.now(),
              buttonsLayout: "vertical",
              buttons: [
                {
                  text: "Закрыть",
                  type: "primary",
                  callback: index => {
                    $closePopup(index);
                  }
                }
              ]
            });
          }
        } catch {}
      });
      Electron().ipcRenderer.on("clear-all", async () => {
        await clearDB();
        if (localStorage.getItem(LSNames.AT)) {
          try {
            await $infra.auth.Logout();
          } catch {}
        }
        Electron().ipcRenderer.send("clear-cache");
        localStorage.clear();
        location.reload();
      });
      if (localStorage.getItem(LSNames.OpenAtLogin) !== null) {
        $store.commit(
          "settingsStore/setOpenAtLogin",
          localStorage.getItem(LSNames.OpenAtLogin) === "1"
        );
      } else {
        localStorage.setItem(LSNames.OpenAtLogin, "1");
        Electron().ipcRenderer.send("set-open-at-login", true);
      }
    };
    const initializeWeb = () => {
      window.onfocus = () => {
        $store.commit("setFocusApp", true);
        if (
          !$store.state.streamStore.isWebSocketOnline ||
          !$store.state.streamStore.webSocket
        ) {
          $store.dispatch("streamStore/initializeCentrifuge");
        }
      };
      window.onblur = () => {
        $store.commit("setFocusApp", false);
      };
      window.addEventListener("resize", () => {
        $store.commit("getMobile");
      });
      isPWA.value = !!(
        navigator["standalone"] ||
        window.matchMedia("(display-mode: standalone)").matches
      );
    };
    const initializeApplication = async () => {
      await $store.dispatch("init");
      $store.commit("setIsBrowser", $service.isBrowser());
      $store.commit("settingsStore/setStageMode", STAGE_MODE);
      if ($store.state.settingsStore.stageMode) {
        const envName = $service.getEnvironment()?.name;

        if (envName !== "tj-stage") {
          $service.logout(true);
          $service.setEnvironment($service.findEnvironmentPreset("tj-stage"));
          await $router.push({
            name: "auth",
            query: {
              logout: "auto"
            }
          });

          return;
        }
      }
      try {
        await $service.updateAndCheckVersion();
      } catch {}
      $store.commit("setIsOnline", window.navigator.onLine);
      window.addEventListener("online", () =>
        $store.commit("setIsOnline", true)
      );
      window.addEventListener("offline", () =>
        $store.commit("setIsOnline", false)
      );
    };
    const toastCloseHandler = (toast: ITNToast) => {
      $store.commit("closeToast", toast.creationTime);
    };
    const popupCloseHandler = (index: number) => {
      $store.commit("closePopup", index);
    };
    const sendForm = (data: { type: ReportType; text: string }) => {
      let type = "TN Life - Обратная связь - ";

      if (data.type === ReportType.Error) {
        type += "Ошибка";
      } else if (data.type === ReportType.Improvement) {
        type += "Предложение";
      } else if (data.type === ReportType.Access) {
        type += "Доступ к сервису";
      } else if (data.type === ReportType.Extra) {
        if (
          typeof feedbackForm.value !== "boolean" &&
          feedbackForm.value.type === "colleagues"
        ) {
          type += "Список коллег";
        } else {
          type += "Прочее";
        }
      } else {
        type += "Прочее";
      }
      const mailLink = `mailto:life@tn.ru?subject=${type}&body=${data.text}`;

      if (isBrowser.value) {
        window.open(encodeURI(mailLink));
      } else {
        Electron().shell.openExternal(encodeURI(mailLink));
      }
    };
    const openReportForm = () => {
      $store.commit("openFeedbackForm");
    };
    const closeReportForm = () => {
      $store.commit("closeFeedbackForm");
    };
    const clearQuery = () => {
      if (Object.keys($route.query).length) {
        $router.push($route.path.split("?")[0]);
      }
    };
    const updateQuery = (
      appId: string,
      query: Record<string, string> | undefined
    ) => {
      $store.dispatch("updateOpenAppQuery", { appId, query });
      $router.push({
        query
      });
    };
    const isShowApp = (app: { id: string; opened: number }): boolean => {
      return $route.name === "app" && $route.params.id === app.id;
    };
    const rejectCall = async (id: string) => {
      const call = incomingCallWindows.value.find(icw => icw.room === id);

      if (call) {
        callsLoading.value.push(call.room);
        try {
          await $infra.meet.DeclineCall(call.room);
          closeIncomingCall(id);
        } catch (e) {
          $error({
            message: "Ошибка отклонения звонка",
            details: JSON.stringify(e)
          });
          const callLoadIndex = callsLoading.value.findIndex(
            i => i === call.room
          );

          if (callLoadIndex !== -1) {
            callsLoading.value.splice(callLoadIndex, 1);
          }
        }
      }
    };
    const cancelWaitingCall = async () => {
      const call = waitingCallWindow.value;

      if (call) {
        callsLoading.value.push(call.room);
        try {
          await $infra.meet.EndMeet(call.room);
          closeWaitingCall();
        } catch (e) {
          $error({
            message: "Ошибка отклонения звонка",
            details: JSON.stringify(e)
          });
          const callLoadIndex = callsLoading.value.findIndex(
            i => i === call.room
          );

          if (callLoadIndex !== -1) {
            callsLoading.value.splice(callLoadIndex, 1);
          }
        }
      }
    };
    const answerCall = async (id: string) => {
      const call = incomingCallWindows.value.find(icw => icw.room === id);

      if (call) {
        callsLoading.value.push(call.room);
        try {
          await $infra.meet.AnswerMeet(call.room);
          closeIncomingCall(id);
          void $store.dispatch("meet", { roomId: call.room, force: true });
        } catch (e) {
          $error({
            message: "Ошибка принятия звонка",
            details: JSON.stringify(e)
          });
          const callLoadIndex = callsLoading.value.findIndex(
            i => i === call.room
          );

          if (callLoadIndex !== -1) {
            callsLoading.value.splice(callLoadIndex, 1);
          }
        }
      }
    };
    const closeWaitingCall = () => {
      $store.dispatch("setWaitingCallWindow", null);

      const callLoadIndex = callsLoading.value.findIndex(
        i => i === waitingCallWindow.value?.room
      );

      if (callLoadIndex !== -1) {
        callsLoading.value.splice(callLoadIndex, 1);
      }
    };
    const closeIncomingCall = (room: string) => {
      $store.dispatch("setIncomingCallWindow", {
        room,
        call: null
      });

      const callLoadIndex = callsLoading.value.findIndex(i => i === room);

      if (callLoadIndex !== -1) {
        callsLoading.value.splice(callLoadIndex, 1);
      }
    };
    const deepLinkHandler = async (data: {
      type: string;
      appId: string;
      payload: string;
    }) => {
      const { type, appId, payload } = data;

      if (type === "user" && appId) {
        if ($route.name === "user-page") {
          await $router.push({
            name: "contacts"
          });
          await nextTick();
        }
        void $router.push({
          name: "user-page",
          params: {
            id: appId
          }
        });
      } else if (type === "app" && appId) {
        void $store.dispatch("openApp", {
          id: appId,
          query: { payload },
          reopen: true
        });
        setTimeout(() => {
          void $router.push({
            name: "app",
            params: {
              id: appId
            },
            query: {
              payload
            }
          });
        }, 20);
      } else if (type === "meet" && appId) {
        void $store.dispatch("meet", { roomId: appId });
      } else if (type === "contacts") {
        await $router.push({
          name: "contacts"
        });
      } else if (type === "chat" && appId) {
        if (appId) {
          await $router.push({
            name: "chat-page",
            params: {
              id: appId
            }
          });
        } else {
          await $router.push({
            name: "chat-list"
          });
        }
      }
      Electron().ipcRenderer.send("clear-deep-link");
    };
    const updateLaterHandler = () => {
      if ($store.state.updateStatus) {
        $store.dispatch("delayNewVersion", {
          date: new Date().setDate(new Date().getDate() + 3),
          version: $store.state.updateStatus.actual
        });
        $store.commit("setUpdateStatus", null);
      }
    };
    const updateDownloadHandler = () => {
      isUpdateDownloading.value = true;
      Electron().ipcRenderer.send("update", { ...$store.state.updateStatus });
    };
    const updateRetryHandler = () => {
      isUpdateError.value = false;
      updateDownloadProgress.value = 0;
      updateDownloadHandler();
    };
    const becameOnlineHandler = () => {
      $store.dispatch("streamStore/init", InitMode.Reload);
    };
    const getPopupShow = () => {
      isPopupShow.value = !!document.body.querySelector(
        ".tn-bottom-sheet__background"
      );
    };

    return {
      feedbackForm,
      toastList,
      popupList,
      isOnboarding,
      isSimpleLayout,
      isNoHeader,
      devMode,
      runningApps,
      maxAppsValue,
      isSubpage,
      isAppFocused,
      isBrowser,
      incomingCallWindows,
      callsLoading,
      ringtoneCheckBoxValue,
      waitingCallWindow,
      isWaitingCallRejected,
      isUpdateDownloading,
      updateDownloadProgress,
      isUpdateError,
      isUpdateHard,
      isUpdateRequired,
      maxAppsWarn,
      payloadString,
      isShortLinkCreating,
      isMobile,
      showTooltipValue,
      isPWA,
      isOnline,
      isCentrifugeOnline,
      toastNoPadding,
      isEmbedded,
      classList,
      updateDownloadHandler,
      updateRetryHandler,
      updateLaterHandler,
      closeIncomingCall,
      closeWaitingCall,
      cancelWaitingCall,
      answerCall,
      rejectCall,
      isShowApp,
      clearQuery,
      updateQuery,
      toastCloseHandler,
      popupCloseHandler,
      sendForm,
      openReportForm,
      closeReportForm
    };
  }
});
