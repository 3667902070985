import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "report-form__success-window"
}
const _hoisted_2 = { class: "report-form__success-icon" }
const _hoisted_3 = { class: "report-form__success-check-icon" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 1,
  class: "report-form__window"
}
const _hoisted_7 = { class: "report-form__form-item-container" }
const _hoisted_8 = { class: "report-form__form-item-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TNIcon = _resolveComponent("TNIcon")!
  const _component_TNSelector = _resolveComponent("TNSelector")!
  const _component_TNTextarea = _resolveComponent("TNTextarea")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    visible: !!_ctx.form,
    popup: _ctx.popup,
    "full-height": _ctx.isMobile,
    "custom-class": ['report-form', _ctx.isFlattened ? 'report-form_success' : ''],
    onClose: _ctx.closeWindow
  }, {
    default: _withCtx(() => [
      (_ctx.isSend)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_Transition, { name: "bounce" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_TNIcon, { name: "check" })
                  ])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _createElementVNode("h4", {
              class: "report-form__success-title",
              innerHTML: _ctx.successTitle
            }, null, 8 /* PROPS */, _hoisted_4),
            _createElementVNode("p", {
              class: "report-form__success-annotation",
              innerHTML: _ctx.successAnnotation
            }, null, 8 /* PROPS */, _hoisted_5)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_TNSelector, {
                modelValue: _ctx.selectedType,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedType) = $event)),
                label: "Тип обращения",
                "chip-titles": "",
                flat: "",
                class: "report-form__selector",
                placeholder: "Не выбран",
                multiple: false,
                clearable: false,
                options: _ctx.typeList,
                disabled: _ctx.isTypeSelectorDisabled
              }, null, 8 /* PROPS */, ["modelValue", "options", "disabled"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_TNTextarea, {
                modelValue: _ctx.reportBody,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.reportBody) = $event)),
                label: "Обращение",
                placeholder: "Распишите свою проблему или предложение",
                class: "report-form__textarea"
              }, null, 8 /* PROPS */, ["modelValue"])
            ])
          ]))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible", "popup", "full-height", "custom-class", "onClose"]))
}