import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { useStore } from "vuex";

import router from "@/router";
import { key } from "@/store";

export async function trackerPageMiddleware(
  _: RouteLocationNormalized,
  __: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const store = useStore(key);

  if (!store.state.settingsStore.enableTracker) {
    router.push({ name: "404" });
  }
  next();
}
