import TNButton from "@life_uikit/uikit/button/button.vue";
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref
} from "vue";

import { IPopup, IPopupButton } from "./i-popup";

export default defineComponent({
  name: "Popup",
  props: {
    popup: { type: Object as PropType<IPopup>, required: true },
    resizable: Boolean,
    visible: { type: Boolean, default: true },
    customClass: {
      type: [String, Array] as PropType<string | string[]>,
      default: ""
    },
    fullHeight: { type: Boolean, default: false },
    lockSwipe: { type: Boolean, default: false },
    isBlockEscapeEvent: { type: Boolean, default: false }
  },
  emits: ["close"],
  setup: (props, { emit }) => {
    const buttons = ref<InstanceType<typeof TNButton>[] | null>(null);
    const resizing = ref<boolean>(false);
    const customWidth = ref<number>(0);
    const customHeight = ref<number>(0);
    const windowElement = ref<HTMLElement | null>(null); // todo: Придумать как поймать контейнер попапа UI Kit
    const startResizeData = ref<{
      top: number;
      left: number;
      width: number;
      height: number;
    } | null>(null);
    const isButtonsLoading = ref<boolean>(false);

    onMounted(() => {
      setTimeout(() => {
        if (buttons.value?.length) {
          const primary = buttons.value.find(
            button =>
              button.$el.className.includes("popup__button") &&
              button.$el.className.includes("tn-button_action")
          );

          if (primary) {
            primary.$el.focus();
          }
        }
      }, 50);
      document.body.addEventListener("keyup", keyUpHandler);
    });
    onBeforeUnmount(() => {
      document.body.removeEventListener("keyup", keyUpHandler);
    });

    // todo: Применить стили ресайза к windowElement
    const customStyle = computed<{ width: string; height: string }>(() => ({
      width: customWidth.value ? customWidth.value + "px" : "",
      height: customHeight.value ? customHeight.value + "px" : ""
    }));
    const customClassList = computed<string[]>(() => {
      const list =
        typeof props.customClass === "string"
          ? [props.customClass]
          : [...props.customClass];

      if (resizing.value) {
        list.push("popup_resizing");
      }
      if (props.popup.error) {
        list.push("popup_error");
      }

      return list;
    });
    const popupHeader = computed<
      { title?: string; description?: string } | undefined
    >(() => ({
      title: props.popup.title,
      description: props.popup.text
    }));

    const closePopup = () => {
      if (!resizing.value && props.visible) {
        emit("close");
      }
    };
    const buttonClickHandler = (button: IPopupButton) => {
      if (button.callback) {
        isButtonsLoading.value = true;
        const response = button.callback(props.popup.index || -1);

        if (response) {
          response.finally(() => {
            isButtonsLoading.value = false;
          });
        } else {
          isButtonsLoading.value = false;
        }
      }
    };
    const keyUpHandler = (event: KeyboardEvent) => {
      if (event.code === "Escape" && !props.isBlockEscapeEvent) {
        closePopup();
      }
    };
    const starResizing = (event: MouseEvent) => {
      resizing.value = true;
      if (windowElement.value) {
        const rect = windowElement.value.getClientRects()[0];

        startResizeData.value = {
          top: +event.clientY,
          left: +event.clientX,
          width: rect.width,
          height: rect.height
        };
        customWidth.value = rect.width;
        customHeight.value = rect.height;
      }
    };
    const resize = (event: MouseEvent) => {
      if (resizing.value) {
        if (windowElement.value && startResizeData.value) {
          const deltaWidth = event.clientX - startResizeData.value.left;
          const deltaHeight = event.clientY - startResizeData.value.top;

          customWidth.value = startResizeData.value.width + deltaWidth * 2;
          customHeight.value = startResizeData.value.height + deltaHeight * 2;
        }
      }
    };
    const stopResizing = () => {
      if (resizing.value) {
        setTimeout(() => {
          startResizeData.value = null;
          resizing.value = false;
        }, 100);
      }
    };

    return {
      resizing,
      windowElement,
      customStyle,
      buttons,
      isButtonsLoading,
      customClassList,
      popupHeader,
      closePopup,
      buttonClickHandler,
      starResizing,
      stopResizing,
      resize
    };
  }
});
